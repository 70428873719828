import React from "react"
import PropTypes from "prop-types";
import AccordionItem from "@/components/elements/accordion-item";

const faq = ({ data }) => {
    return (
        <section className='container py-8 lg:py-12'>
            {/* Accordion item has 4 padding so we only do 2 margin here */}
            <h2 className='title text-primary mb-2'>{data.title}</h2>
            {data.accordion.map((accordionItem) => (
                <div className='text-lg'>
                    <AccordionItem accordion={accordionItem} />
                </div>
            ))}
        </section>
    );
}

faq.propTypes = {
    data: PropTypes.shape({
        quote: PropTypes.string,
        author: PropTypes.string,
    }),
};

export default faq;