import React, { useCallback, useReducer } from "react"
import { MdCheck } from "react-icons/md";
import classNames from "classnames";
import ButtonLink from "../elements/button-link";
import { getButtonAppearance } from "@/utils/button"
import { getLocalizationKey } from "../../utils/localize";
import { useCookies } from "react-cookie";

const PriceTable = ({ data, pageContext, pricing }) => {
  const [cookies, setCookie] = useCookies();

  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);
  pricing = pricing.pricing;

  const initRangeState = pricing.plans.filter((plan) => plan.priceRange.length).reduce((pv, cv) => {
    pv[cv.id] = {
      id: cv.id, count: cv.defaultRangeValue || cv.priceRange[0].count, values: cv.priceRange.reduce((pv, cv) => {
        pv[cv.count] = cv.amount;
        return pv;
      }, {})
    };
    return pv;
  }, {});

  const [ranges, rangesDispatch] = useReducer((state, action) => {
    return {
      ...state,
      [action.id]: { ...state[action.id], count: action.count }
    }
  }, initRangeState);

  const initPriceState = pricing.plans.reduce((pv, cv) => (
    pv[cv.id] = {
      expanded: false,
    }
  ), {});

  const [priceState, pricesDispatch] = useReducer((state, action) => {
    return {
      ...state,
      [action.id]: { expanded: action.expanded }
    }
  }, initPriceState);

  const individualFeatures = [];
  const teamFeatures = [];
  const enterpriseFeatures = [];

  data.features.forEach(feature => {
    if (feature.individual)
      individualFeatures.push(feature);
    if (feature.team)
      teamFeatures.push(feature);
    if (feature.enterprise)
      enterpriseFeatures.push(feature);
  });

  const getClickFn = useCallback((index) => {
    // Individiual
    if (index === 0) {
      return;
    }
    if (index === 1) {
      setCookie('prefillMessage', getLocalization('CONTACT_PREFILL_TEAM').replace('$TOKEN', ranges[pricing.plans[1].id].count));
      return;
    }
    if (index === 2) {
      setCookie('prefillMessage', getLocalization('CONTACT_PREFILL_ENTERPRISE'));
      return;
    }
  }, [setCookie, ranges]);

  const renderMobilePlan = (plan, index) => {
    let correspondingFeatures = [];
    if (index === 0) correspondingFeatures = individualFeatures;
    if (index === 1) correspondingFeatures = teamFeatures;
    if (index === 2) correspondingFeatures = enterpriseFeatures;

    return <>
      <div className='bg-gray flex-1 pb-16'>
        {/* Plan name & description */}
        <div
          className={classNames(
            // Common
            'p-4 pt-8',
            // Normal plan
            {
              "bg-gray-dark text-white": !plan.isRecommended,
            },
            // Recommended plan
            {
              "bg-primary text-white border-primary":
                plan.isRecommended,
            }
          )}
          key={plan.id}
        >
          <h2 className="text-4xl">{plan.name}</h2>
          <p
            className={classNames("mt-4 text-2xl", {
              "text-white": plan.isRecommended,
              "text-white": !plan.isRecommended,
            })}
          >
            {plan.priceRange.length ?
              <div className='flex items-center pr-2'>
                <span className='w-8 text-center'>{ranges[plan.id].count}</span><span className='mr-4'>{plan.licenseSize ? ` ${plan.licenseSize}` : ''}</span>
                <input className='flex-grow' type='range' defaultValue={plan.defaultRangeValue !== null ? plan.defaultRangeValue : plan.priceRange[0].count} min={plan.priceRange[0].count} max={plan.priceRange[plan.priceRange.length - 1].count} onInput={(ev) => rangesDispatch({ id: plan.id, count: ev.target.value })} />
              </div>
              :
              plan.licenseSize
            }
          </p>
        </div>

        {/* Price & features */}
        <div className='p-4'>
          <div className='lg:h-10 lg:flex lg:items-end lg:overflow-hidden'>
            {plan.priceRange.length ?
              <span className='text-4xl'>{`$${Number(ranges[plan.id].values[ranges[plan.id].count]).toLocaleString('en-US')}`}</span>
              :
              !plan.price ? <span className='text-xl'>{getLocalization('CONTACT_US')}</span> : <span className='text-4xl'>{`$${Number(plan.price).toLocaleString('en-US')}`}</span>
            }
            {plan.pricePeriod &&
              <span className="ml-2 text-xl">{plan.pricePeriod}</span>
            }
          </div>
          <div className='py-4 flex'>
            <ButtonLink
              button={plan.button}
              onClickCapture={() => getClickFn(index)}
              appearance={getButtonAppearance(plan.button.type, "light")}
            />
          </div>
          <ul className="my-4 flex flex-col border-gray-light border-t">
            {correspondingFeatures.map(feature => (
              <li
                className="flex flex-row justify-start items-center text-xl border-gray-light border-b py-2 m-0"
                key={feature.id}
              >
                <MdCheck className="flex-shrink-0 h-6 w-auto text-white mr-2 stroke-2" />
                <span className='px-4'>{feature.featureName}</span>
              </li>
            ))}
          </ul>
          {/* <button className='text-lg' onClick={() => pricesDispatch({ id: plan.id, expanded: true })}>See more</button> */}
        </div>

      </div>

      {/* Mobile description */}
      <div className={classNames('lg:hidden flex flex-col max-w-sm text-white lg:flex-row gap-8 lg:justify-center px-4',
        {
          'mb-8': (index !== pricing.plans.length - 1)
        })}>
        <p className='text-gray'>{plan.description}</p>
      </div>
    </>
  }

  return (
    <div className="container py-8 lg:py-12 items-center flex flex-col lg:block">
      {/* Desktop table */}
      <table className="hidden lg:table w-full table-fixed border-collapse lg:flex-row gap-8 lg:justify-center mt-6 break-words">
        <thead className='text-white'>
          <tr>
            <th></th>
            {pricing.plans.map((plan) => (
              <th className={classNames(
                // Common
                'border border-gray-light p-4 font-normal text-left align-baseline',
                // Normal plan
                {
                  "bg-gray-dark text-white": !plan.isRecommended,
                },
                // Recommended plan
                {
                  "bg-primary text-white":
                    plan.isRecommended,
                }
              )}
                key={plan.id}
              >
                <h2 className="text-3xl font-thin">{plan.name}</h2>
                <p className="text-4xl pt-4">
                  {plan.priceRange.length ?
                    `$${Number(ranges[plan.id].values[ranges[plan.id].count]).toLocaleString('en-US')}`
                    :
                    !plan.price ? <span className='text-xl'>{getLocalization('CONTACT_US_FOR_PRICING')}</span> : `$${Number(plan.price).toLocaleString('en-US')}`
                  }
                  <span className="lg:ml-2 block lg:inline-block text-xl font-thin">{plan.pricePeriod}</span>
                </p>
                <p
                  className={classNames("mt-4 text-2xl", {
                    "text-white": plan.isRecommended,
                    "text-white": !plan.isRecommended,
                  })}
                >
                  {plan.priceRange.length ?
                    <div className='flex items-center lg:flex-wrap gap-4'>
                      <div className='flex'>
                        <span className='w-8 flex-shrink-0 text-center'>{ranges[plan.id].count}</span><span className=''>{plan.licenseSize ? ` ${plan.licenseSize}` : ''}</span>
                      </div>
                      <input type='range' defaultValue={plan.defaultRangeValue !== null ? plan.defaultRangeValue : plan.priceRange[0].count} min={plan.priceRange[0].count} max={plan.priceRange[plan.priceRange.length - 1].count} onInput={(ev) => rangesDispatch({ id: plan.id, count: ev.target.value })} /></div>
                    :
                    plan.licenseSize
                  }
                </p>
              </th>
            ))}

          </tr>
        </thead>

        <tbody>
          {data.features.map(feature => (
            <tr key={feature.id}>
              <td className='p-2 border border-gray-light text-gray 2xl:text-lg'>{feature.featureName}</td>
              <td className='p-2 border border-gray-light'>{feature.individual && <MdCheck className='my-0 mx-auto text-3xl text-primary' />}</td>
              <td className='p-2 border border-gray-light'>{feature.team && <MdCheck className='my-0 mx-auto text-3xl text-primary' />}</td>
              <td className='p-2 border border-gray-light'>{feature.enterprise && <MdCheck className='my-0 mx-auto text-3xl text-primary' />}</td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td className='border border-gray-light'></td>
            {pricing.plans.map((plan, i) => {
              return <td key={plan.id} className='p-4 border border-gray-light text-white text-center'>
                <span className='inline-block'>
                  <ButtonLink
                    button={plan.button}
                    onClickCapture={() => getClickFn(i)}
                    appearance={getButtonAppearance(plan.button.type, "light")}
                  />
                </span>
              </td>
            })}
          </tr>
        </tfoot>
      </table>

      {/* Mobile */}
      <div className="price-grid grid grid-cols-1 text-white gap-4 md:w-6/12 lg:hidden">
        {pricing.plans.map((plan, index) => (
          renderMobilePlan(plan, index)
        ))}
      </div>
    </div >
  );
};

export default PriceTable;
