import React, { useEffect } from "react"
import Hero from "@/components/sections/hero"
import HomeHero from "@/components/sections/home-hero"
import LargeVideo from "@/components/sections/large-video"
import LargeVideoLink from "@/components/sections/large-video-link"
import LargeImage from "@/components/sections/large-image"
import FeatureColumnsGroup from "@/components/sections/feature-columns-group"
import NumberFeatureRowsGroup from "@/components/sections/number-feature-rows-group"
import BulletFeatureRowsGroup from "@/components/sections/bullet-feature-rows-group"
import RichTextFeatureRowsGroup from "@/components/sections/rich-text-feature-rows-group"
import BottomActions from "@/components/sections/bottom-actions"
import TestimonialsGroup from "@/components/sections/testimonials-group"
import FreeTrial from "@/forms/free-trial"
import ScheduleDemo from "@/forms/schedule-demo"
import ContactUs from "@/forms/contact-us"
import SignUp from "@/forms/sign-up"
import RichText from "./sections/rich-text"
import Pricing from "./sections/pricing"
import LeadForm from "./sections/lead-form"
import { useCookies } from "react-cookie"
import { navigate } from "gatsby-link"
import { useLocation } from "@reach/router"
import ExamplesGroup from "@/components/sections/examples-group"
import ExamplesCategoryAnchorLinks from "@/components/sections/examples-category-anchor-links"
import Quote from "@/components/sections/quote"
import LargeText from "@/components/sections/large-text"
import PriceTable from "@/components/sections/price-table"
import faq from "@/components/sections/faq"
import Carousel from "@/components/sections/carousel"

// Map Strapi sections to section components
const sectionComponents = {
  Strapi_ComponentSectionsHero: Hero,
  Strapi_ComponentSectionsHomeHero: HomeHero,
  Strapi_ComponentSectionsLargeVideo: LargeVideo,
  Strapi_ComponentSectionsLargeVideoLink: LargeVideoLink,
  Strapi_ComponentSectionsLargeImage: LargeImage,
  Strapi_ComponentSectionsCarousel: Carousel,
  Strapi_ComponentSectionsFeatureColumnsGroup: FeatureColumnsGroup,
  Strapi_ComponentSectionsNumberFeatureRowsGroup: NumberFeatureRowsGroup,
  Strapi_ComponentSectionsBulletFeatureRowsGroup: BulletFeatureRowsGroup,
  Strapi_ComponentSectionsRichTextFeatureRowsGroup: RichTextFeatureRowsGroup,
  Strapi_ComponentSectionsExamplesGroup: ExamplesGroup,
  Strapi_ComponentSectionsExamplesCategoryAnchorLinks: ExamplesCategoryAnchorLinks,
  Strapi_ComponentSectionsBottomActions: BottomActions,
  Strapi_ComponentSectionsTestimonialsGroup: TestimonialsGroup,
  Strapi_ComponentSectionsRichText: RichText,
  Strapi_ComponentSectionsQuote: Quote,
  Strapi_ComponentSectionsLargeText: LargeText,
  Strapi_ComponentSectionsFaq: faq,
  Strapi_ComponentSectionsPriceTable: PriceTable,
  Strapi_ComponentSectionsPricing: Pricing,
  Strapi_ComponentSectionsLeadForm: LeadForm,
  Strapi_ComponentFormsFreeTrial: FreeTrial,
  Strapi_ComponentFormsContactUs: ContactUs,
  Strapi_ComponentFormsScheduleDemo: ScheduleDemo,
  Strapi_ComponentFormsSignUp: SignUp,
}

const previewComponents = {
  "sections.hero": Hero,
  "sections.home-hero": HomeHero,
  "sections.large-video": LargeVideo,
  "sections.large-video-link": LargeVideoLink,
  "sections.large-image": LargeImage,
  "sections.examples-group": ExamplesGroup,
  "sections.example-category-anchor-links": ExamplesCategoryAnchorLinks,
  "sections.carousel": Carousel,
  "sections.feature-columns-group": FeatureColumnsGroup,
  "sections.number-feature-rows-group": NumberFeatureRowsGroup,
  "sections.bullet-feature-rows-group": BulletFeatureRowsGroup,
  "sections.rich-text-feature-rows-group": RichTextFeatureRowsGroup,
  "sections.bottom-actions": BottomActions,
  "sections.testimonials-group": TestimonialsGroup,
  "sections.rich-text": RichText,
  "sections.faq": faq,
  "sections.price-table": PriceTable,
  "sections.pricing": Pricing,
  "sections.lead-form": LeadForm,
  "forms.free-trial": FreeTrial,
  "forms.schedule-demo": ScheduleDemo,
  "forms.contact-us": ContactUs,
  "forms.sign-up": SignUp,
}

const PreviewModeBanner = ({ location }) => {
  return (
    <div className="py-4 bg-red-600 text-red-100 font-semibold uppercase tracking-wide">
      <div className="container">
        Preview mode is on.{" "}
        <button
          className="underline"
          onClick={() => {
            // The cookie will be deleted by a useEffect in the Section component
            navigate("/", { state: { prevPath: location.pathname } })
          }}
        >
          Turn off
        </button>
      </div>
    </div>
  )
}

// Display a section individually
const Section = ({ sectionData, pageContext, pricing, scheduleDemo }) => {
  // Prepare the component
  let SectionComponent
  if (sectionData.__component) {
    SectionComponent = previewComponents[sectionData.__component]
  } else {
    SectionComponent = sectionComponents[sectionData.__typename]
  }

  if (!SectionComponent) {
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} pageContext={pageContext} pricing={pricing} scheduleDemo={scheduleDemo} />
}

// Display the list of sections
const Sections = ({ sections, pageContext, pricing, scheduleDemo }) => {
  const location = useLocation()
  // Ignore unused destructured variable
  // eslint-disable-next-line
  const [cookies, _, removeCookie] = useCookies(["strapiPreview"])

  useEffect(() => {
    // The preview cookie is deleted when state.prevPath exists on location
    if (location.state && location.state.prevPath) {
      removeCookie("strapiPreview", {
        path: '/',
        secure: process.env.NODE_ENV === "production",
        sameSite: "Strict",
      })
    }
  }, [location, removeCookie])
  
  return (
    <div className="flex flex-col">
      {process.env.GATSBY_PREVIEW_SECRET &&
        cookies.strapiPreview === process.env.GATSBY_PREVIEW_SECRET && (
          <PreviewModeBanner location={location} />
        )}
      {sections.map((section, i) => (
        <Section
          sectionData={section}
          pageContext={pageContext}
          pricing={pricing}
          scheduleDemo={scheduleDemo}
          key={`${section.__component}${(section.id, i)}`}
        />
      ))}
    </div>
  )
}

export default Sections
