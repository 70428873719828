import React from "react"
import Markdown from "@/utils/markdown";
import Image from "../image"
import Video from '@/components/elements/video'

const Hero = ({ data }) => {

  return (
    <main className='bg-primary lg:my-4'>
      <div className="container hero-container relative flex flex-col md:flex-row items-start justify-between lg:justify-start bg-primary text-white font-100 lg:h-96">
        {/* Left column for content */}
        <div className="flex-1 lg:flex-none sm:pr-8 py-8 lg:py-20 lg:w-6/12 lg:flex-grow-0">
          {/* Hero section label */}
          <p className="uppercase tracking-wide">{data.label}</p>
          {/* Big title */}
          <h1 className="title mb-2 lg:mb-6">{data.title}</h1>
          {/* Description paragraph */}
          <h2 className="text-xl 2xl:text-2xl">{data.description}</h2>
          {/* Small rich text */}
          <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
            <Markdown className='prose'>{data.smallTextWithLink}</Markdown>
          </div>
        </div>
        {/* Right column for the image */}
        <div className='hero-img-container hidden lg:block flex-shrink-0 object-contain w-full md:w-9/12 lg:w-6/12 mt-6 md:mt-0 relative -top-4 h-full'>
          {data.picture.mime.includes('image') &&
            <Image
              media={data.picture}
              className="hero-img"
            />
          }
          {data.picture.mime.includes('video') &&
            <Video
              media={data.picture}
              controls={false}
              autoPlay={true}
              ref={videoRef}
              loop
              className="hero-img"
            />
          }
        </div>
      </div>
    </main>
  )
}

export default Hero
