import React from "react"
import Image from "../image";

const LargeImage = ({ data }) => {
  return (
    <section className="container flex flex-col align-middle text-center p-8 lg:py-12">
      {data.text && <h3 className="title text-primary mb-6">{data.text}</h3>}
      {/* Image wrapper */}
      <div className="w-full lg:w-8/12 mx-auto overflow-hidden">
        <Image
          media={data.image}
          className="w-full max-h-full"
        />
      </div>
    </section>
  );
};

export default LargeImage;
