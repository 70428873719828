import React from "react"
import PropTypes from "prop-types";

const Quote = ({ data }) => {
    return (
        <section className='bg-primary text-white lg:mb-20'>
            <div className='container p-8 flex flex-col justify-center items-start'>
                <div className='text-9xl h-16 lg:h-24'>❝</div>
                <div className="px-8 lg:px-24 pb-4 text-xl italic">
                    {data.quoteText}❞
                </div>
                <div className='text-xl self-end pr-24'>
                    -{data.author}
                </div>
            </div>
        </section>
    );
};

Quote.propTypes = {
    data: PropTypes.shape({
        quote: PropTypes.string,
        author: PropTypes.string,
    }),
};

export default Quote;