import React from "react"
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { getStrapiMedia } from "@/utils/media";
import { mediaPropTypes } from "@/utils/types";

const Video = ({
  media,
  poster,
  className,
  controls = true,
  autoPlay = false,
  loop = false,
  playsinline = true,
}, ref) => {
  const fullVideoUrl = getStrapiMedia(media.url);
  const fullPosterUrl = getStrapiMedia(poster?.url);

  return (
    // TODO: Videos must have captions track
    // eslint-disable-next-line
    <video
      className={className}
      poster={fullPosterUrl}
      controls={controls}
      playsInline={playsinline}
      autoPlay={autoPlay}
      muted={autoPlay}
      loop={loop}
      ref={ref}
    >
      <source src={fullVideoUrl} type={media.mime} />
    </video>
  );
};

Video.propTypes = {
  media: mediaPropTypes.isRequired,
  poster: mediaPropTypes,
  className: PropTypes.string,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

export default forwardRef(Video);
