import React from "react"
import classNames from "classnames";
import Image from "../image";
import Video from "../elements/video";
import ButtonLink from "../elements/button-link";
import { getButtonAppearance } from "../../utils/button";
import Markdown from "@/utils/markdown";
import { getStrapiMedia } from "../../utils/media";

const RichTextFeatureRowsGroup = ({ data }) => {
  return (
    <div className="container flex flex-col gap-8 lg:gap-12 py-8 lg:py-12">
      {data.features.map((feature, index) => (
        <div
          className={classNames(
            // Common classes
            "flex flex-col justify-start gap-36 md:justify-between md:items-center overflow-hidden",
            {
              "lg:flex-row": (index % 2 === 0 && data.richTextLeadingImagePlacement === 'right') || (index % 2 === 1 && data.richTextLeadingImagePlacement === 'left'),
              "lg:flex-row-reverse": (index % 2 === 1 && data.richTextLeadingImagePlacement === 'right') || (index % 2 === 0 && data.richTextLeadingImagePlacement === 'left'),
            }
          )}
          key={feature.id}
        >
          {/* Text section */}
          <div className={classNames("w-full lg:w-7/12 xl:max-w-2xl text-lg",
          { 'mr-auto': data.richTextLeadingImagePlacement === 'left' },
          { '': data.richTextLeadingImagePlacement === 'right' }
          )}>
            <h3 className="title text-primary mb-4 lg:mb-6">{feature.title}</h3>
            <Markdown>{feature.description.replaceAll('/uploads/', getStrapiMedia('/uploads/'))}</Markdown>
            {feature.button &&
              <div className="mt-4 flex flex-row justify-start flex-wrap gap-4">
                <ButtonLink button={feature.button} appearance={getButtonAppearance(feature.button.type, 'light')}>
                  <div className="text-blue-600 with-arrow hover:underline">
                    {feature.button.text}
                  </div>
                </ButtonLink>
              </div>
            }
          </div>
          {/* Media section */}
          <div className={classNames("hidden lg:block w-full lg:w-5/12 max-h-full relative",
            {
              "": (index % 2 === 0 && data.richTextLeadingImagePlacement === 'right') || (index % 2 === 1 && data.richTextLeadingImagePlacement === 'left'),
              "": (index % 2 === 1 && data.richTextLeadingImagePlacement === 'right') || (index % 2 === 0 && data.richTextLeadingImagePlacement === 'left'),
            }
          )}>
            {/* Images */}
            {feature.media.mime.startsWith("image") && (
              <Image media={feature.media} className="w-full h-auto" />
            )}
            {/* Videos */}
            {feature.media.mime.startsWith("video") && (
              <Video
                media={feature.media}
                className="w-full h-auto"
                autoPlay
                controls={false}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RichTextFeatureRowsGroup;
