import React from "react"
import Image from "../image";
import { getLocalizationKey } from "@/utils/localize";

const ExamplesGroup = ({ data, pageContext }) => {

  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  return (
    <section className='container py-8 lg:pb-20'>
       <>
          <div className='flex justify-between items-baseline mb-6'>
            <div id={data.category.replace(' ', '-')} className='anchor title text-primary'>{data.category}</div>
            <a href='#examples' className='text-primary'>{getLocalization('RETURN_TO_TOP')}</a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-12">
            {data.examples.map((example, index) => (
              <div
                className='flex flex-col'
                key={example.id}
              >
                {/* Media section */}
                <div className="w-full sm:9/12 max-h-full">
                  {/* Images */}
                  <Image media={example.image} className="w-full h-auto" />
                </div>
                <div className='text-2xl my-4 text-primary'>
                  {example.title}
                </div>
                <div>
                  {example.description}
                </div>
                <div className='mt-4'>
                  <a className='text-primary text-xl' href={example.url} target='_blank'>
                    {getLocalization('VIEW_IN_CAUSELINK')}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </>
    </section>
  );
};

export default ExamplesGroup;
