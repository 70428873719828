
import React from "react"

const ExamplesCategoryAnchorLinks = ({ data }) => {
  return (
    <section className="container py-8 lg:py-12 text-center">
      <div id='examples' className='flex flex-row flex-wrap items-center lg:items-start lg:items-center justify-center gap-4'>
        {data.categories.map((category) => (
          <a className='px-10 py-2 text-xl border border-primary rounded-full hover:no-underline transition-colors hover:bg-primary hover:text-white' href={`#${category.text.replace(' ', '-')}`}>{category.text}</a>
        ))}
      </div>
    </section>
  )
}

export default ExamplesCategoryAnchorLinks
