import React from "react"
import classNames from "classnames";
import Image from "../image";
import Video from "../elements/video";

const NumberFeatureRowsGroup = ({ data }) => {
  const getImagePlacement = (index) => {
    if ((index % 2 === 0 && data.numberLeadingImagePlacement === 'right') || (index % 2 === 1 && data.numberLeadingImagePlacement === 'left')) {
      return 'right'
    }
    return 'left';
  }
  return (
    <div className={classNames("container flex flex-col gap-12 py-8 lg:py-12 lg:px-0 lg:max-w-none")}>
      {data.features.map((feature, index) => (
        <div
          className={classNames(
            // Common classes
            "flex flex-col justify-start md:justify-between md:items-center overflow-hidden",
            {
              "lg:flex-row": getImagePlacement(index) === 'right',
              "lg:flex-row-reverse": getImagePlacement(index) === 'left',
            }
          )}
          key={feature.id}
        >

          {/* Mobile number/title */}
          <div className='lg:hidden mb-6 flex gap-4 self-start'>
            <span className='flex-shrink-0 text-5xl text-white bg-primary h-12 w-12 inline-flex justify-center items-center pt-1'>{index + 1}</span>
            <h3 className="title text-primary">{feature.title}</h3>
          </div>

          {/* Media section */}
          <div className={classNames("lg:order-2 feature-media w-full sm:9/12 max-h-full relative",
            {
              "lg:-right-32": getImagePlacement(index) === 'right',
              "lg:-left-32": getImagePlacement(index) === 'left',
              [getImagePlacement(index)]: true
            }
          )}>
            {/* Images */}
            {feature.media.mime.startsWith("image") && (
              <Image media={feature.media} className="w-full h-auto" />
            )}
            {/* Videos */}
            {feature.media.mime.startsWith("video") && (
              <Video
                media={feature.media}
                className="w-full h-auto"
                autoPlay
                controls={false}
              />
            )}
          </div>

          <div className={classNames('feature-text flex flex-wrap lg:flex-nowrap items-center lg:items-start gap-6 w-full self-start lg:mt-8',
            {
              'right px-4 lg:p-0 lg:pl-16': getImagePlacement(index) === 'right',
              'left px-4 lg:p-0 lg:pr-16': getImagePlacement(index) === 'left',
            })}>
            {/* Desktop Number */}
            <span className='hidden lg:inline-flex flex-shrink-0 text-5xl text-white bg-primary h-16 w-16 justify-center items-center pt-1'>{index + 1}</span>

            {/* Text section */}
            <div className="hidden flex lg:block text-lg gap-4 lg:gap-0">
              {/* Desktop title */}
              <h3 className="hidden lg:block title text-primary">{feature.title}</h3>

              {/* Desktop description */}
              <p className="hidden lg:block mt-6">{feature.description}</p>
            </div>

            {/* Mobile description */}
            <p className="text-lg w-full lg:hidden">{feature.description}</p>
          </div>

        </div>
      ))}
    </div>
  );
};

export default NumberFeatureRowsGroup;
