
import React, { useState } from "react"
import PropTypes from "prop-types";
import classNames from "classnames";
import Markdown from "@/utils/markdown";

const AccordionItem = ({ accordion }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={classNames('border-b border-gray-light transition-all transition-margin overflow-hidden transition-padding pb-0',
            {
                'pb-4': isOpen
            }
        )}>
            <button className={classNames('flex justify-between w-full items-center text-left font-thin py-4 transition-colors', { 'text-primary': isOpen }, { 'text-gray-light hover:text-black': !isOpen })}
                onClick={() => setIsOpen(!isOpen)}>
                {accordion.header}
                <span style={{ top: 3 }} className={classNames('relative mr-4 text-2xl font-thin leading-snug transition-all', { 'opacity-0': !isOpen })}>✕</span>
            </button>
            <div className={classNames(
                'accordion-content container overflow-hidden transition-height',
                {
                    'open': isOpen
                }
            )}>
                <Markdown>{accordion.body}</Markdown>
            </div>
        </div>

    );
}

AccordionItem.propTypes = {
    accordion: PropTypes.shape({
        header: PropTypes.string,
        body: PropTypes.string,
    }),
};

export default AccordionItem;