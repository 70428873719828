
import React, { useCallback, useEffect, useState } from "react"
import Markdown from "@/utils/markdown";
import { useCookies } from "react-cookie"
import { getLocalizationKey } from "@/utils/localize";
import { postFormDataAsJson } from '@/utils/api';
import countriesMap from '@/utils/countries';
import classNames from "classnames";
import { getButtonClassNames } from "@/components/elements/button";

const SignUp = ({ data, pageContext }) => {

  const [cookies, setCookie] = useCookies();
  const [currency] = useState(
    cookies.CURRENCY || '$'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDiscountCode, setIsLoadingDiscountCode] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (selectedCountry !== '') {
      setStates(countriesMap[selectedCountry]);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  // Form is valid on front end
  const [valid, setValid] = useState(false);

  const [price, setPrice] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [discountValid, setDiscountValid] = useState('');

  // Form was sent successfully
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const getPrice = useCallback(async (discountCode) => {
    const response = await fetch('/api/price', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Annual: true, DiscountCode: discountCode }),
    });
    const data = await response.json();
    setPrice(data);
  }, [setPrice]);

  const applyDiscount = useCallback(async (applicantCode) => {
    setIsLoadingDiscountCode(true);
    if (!applicantCode) {
      setDiscountValid('');
      setIsLoadingDiscountCode(false);
      return;
    }
    const response = await fetch('/api/discount-code', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Code: applicantCode }),
    });
    const data = await response.json();
    if (response.ok) {
      await getPrice(data ? applicantCode : '');
      setDiscountValid(data);
      setIsLoadingDiscountCode(false);
    }
  }, []);

  useEffect(getPrice, []);

  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  const currentYear = new Date().getFullYear();

  const [errors, setErrors] = useState([]);
  const [errorsObject, setErrorsObject] = useState({});

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const form = event.currentTarget;

    const url = form.action;

    try {
      const formData = new FormData(form);
      const plainFormData = Object.fromEntries(formData.entries());
      const formattedFormData = {
        Info: {
          DiscountCode: plainFormData.DiscountCode,
          Annual: true,
          CardNumber: plainFormData.CardNumber,
          DiscountCode: plainFormData.DiscountCode,
        },
        Person: {
          FirstName: plainFormData.FirstName,
          LastName: plainFormData.LastName,
          Email: plainFormData.Email,
          Company: plainFormData.Company,
          Phone: plainFormData.Phone,
        },
        Address: {
          Address: plainFormData.Address,
          Address2: plainFormData.Address2,
          Country: plainFormData.Country,
          City: plainFormData.City,
          State: plainFormData.State,
          Zip: plainFormData.Zip,
        },
        Payment: {
          CardNumber: plainFormData.CardNumber,
          ExpirationMonth: plainFormData.ExpirationMonth,
          ExpirationYear: plainFormData.ExpirationYear,
          Cvv2: plainFormData.Cvv2,
        },
      }
      const formDataJsonString = JSON.stringify(formattedFormData).replaceAll(/\"(true)\"/g, true).replaceAll(/\"ExpirationMonth\":\"(\d+)\"/g, '"ExpirationMonth":$1').replaceAll(/\"ExpirationYear\":\"(\d+)\"/g, '"ExpirationYear":$1');

      const fetchOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: formDataJsonString,
      };

      const response = await fetch(url, fetchOptions);

      if (response.ok) {
        const text = await response.text();
        const parsedText = JSON.parse(text);
        if (parsedText.IsValid) {
          setSubmitSuccess(true);
        } else {
          setErrors(Object.keys(parsedText.Errors))
          setErrorsObject(parsedText.Errors)
        }
      }
    }

    catch (error) {
      const errors = JSON.parse(error.message);
      if (Array.isArray(errors)) {
        setErrors(JSON.parse(error.message));
      }
    }

    finally {
      setIsLoading(false);
    }
  };

  return (
    <section className={classNames('container flex flex-col gap-8 lg:gap-12 py-8 lg:py-12', {
      'filter blur pointer-events-none': !price
    })}>
      {!submitSuccess ?
        <>
          <div>
            {errors.includes('Generic') &&
              <div class="text-center xl:w-280 text-red-600 m-4">{errorsObject['Generic']}</div>
            }
            <h2 className='subtitle text-primary mb-4'>{getLocalization('ORDER_TOTAL')}</h2>
            <p><span className={classNames('text-4xl', {})}>{currency}{price.toFixed(2)}</span><span className='ml-2'>{getLocalization("PER_YEAR")}</span></p>
          </div>
          <form action={'/api/sign-up'} onSubmit={handleSubmit} onChange={(ev) => setValid(ev.currentTarget.checkValidity())} className='flex flex-col flex-wrap gap-10 justify-start max-w-md lg:max-w-none '>

            {/* Grid */}
            <div className='grid grid-cols-1 lg:grid-cols-2 auto-rows-max gap-10 lg:gap-24 xl:w-280'>
              {/* Your information section */}
              <div className=''>
                <h2 className='subtitle mb-6 text-primary'>{getLocalization('YOUR_INFORMATION')}</h2>
                {/* First name */}
                <div class="floating-label-container">
                  <input
                    id="FirstName"
                    type="text"
                    name="FirstName"
                    autoComplete='given-name'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="FirstName" className='floating-label'>{getLocalization('FIRST_NAME')}</label>
                  {errors.includes('FirstName') &&
                    <span class="text-sm text-red-600">{errorsObject['FIRST_NAME']}</span>
                  }
                </div>

                {/* Last name */}
                <div className="floating-label-container">
                  <input
                    id="LastName"
                    type="text"
                    name="LastName"
                    autoComplete='family-name'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="LastName" className='floating-label'>{getLocalization('LAST_NAME')}</label>
                  {errors.includes('LastName') &&
                    <span class="text-sm text-red-600">{errorsObject['LAST_NAME']}</span>
                  }
                </div>

                {/* Email */}
                <div className="floating-label-container">
                  <input
                    id="Email"
                    type="email"
                    name="Email"
                    autoComplete='email'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="Email" className='floating-label'>{getLocalization("EMAIL")}</label>
                  {errors.includes('Email') &&
                    <span class="text-sm text-red-600">{errorsObject['Email']}</span>
                  }
                </div>

                {/* Company */}
                <div className="floating-label-container">
                  <input
                    id="Company"
                    type="text"
                    name="Company"
                    autoComplete='organization'
                    placeholder=" "
                    className="floating-label-input"
                  />
                  <label for="company" className='floating-label'>{getLocalization("COMPANY")}</label>
                  {errors.includes('Company') &&
                    <span class="text-sm text-red-600">{errorsObject['Company']}</span>
                  }
                </div>

                {/* Phone */}
                <div className="floating-label-container">
                  <input
                    id="Phone"
                    type="tel"
                    name="Phone"
                    autoComplete='tel'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="company" className='floating-label'>{getLocalization("PHONE")}</label>
                  {errors.includes('Phone') &&
                    <span class="text-sm text-red-600">{errorsObject['Phone']}</span>
                  }
                </div>

                {/* Discount code */}
                <div className='flex items-baseline mt-7'>
                  <div className="floating-label-container md">
                    <input
                      id="DiscountCode"
                      type="text"
                      name="DiscountCode"
                      placeholder=" "
                      className="floating-label-input"
                      onChange={(ev) => { setDiscountCode(ev.target.value); setDiscountValid('') }}
                    />
                    <label for="DiscountCode" className='floating-label'>{getLocalization("DISCOUNT_CODE")}</label>
                    {discountValid === false &&
                      <span class="text-sm text-red-600">{getLocalization("DISCOUNT_CODE")} {getLocalization('IS_INVALID')}</span>
                    }
                    {discountValid === true &&
                      <span class="text-sm text-green-600">{getLocalization("DISCOUNT_CODE_IS_VALID")}</span>
                    }
                  </div>
                  <button disabled={!discountCode} onClick={(ev) => { ev.preventDefault(); applyDiscount(discountCode); }} className={classNames(getButtonClassNames('dark', true), 'ml-6 uppercase', {
                    'is-loading': isLoadingDiscountCode
                  })}>{getLocalization('APPLY')}</button>
                </div>

              </div>

              {/* Billing Address section */}
              <div className=''>

                <h2 className='subtitle mb-6 text-primary'>{getLocalization('BILLING_INFORMATION')}</h2>

                {/* Country */}
                <div className="floating-label-container">
                  {/* <input
                  id="Country"
                  type="text"
                  name="Country"
                  autoComplete='Country-name'
                  placeholder=" "
                  required
                  className="floating-label-input"
                /> */}
                  <select
                    id="Country"
                    name="Country"
                    required
                    placeholder=" "
                    className="floating-label-input"
                    onChange={(ev) => setSelectedCountry(ev.currentTarget.selectedOptions[0].value)}
                  >
                    <option> </option>
                    {Object.keys(countriesMap).map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                  <label for="Country" className='floating-label'>{getLocalization("Country")}</label>
                  {errors.includes('Country') &&
                    <span class="text-sm text-red-600">{errorsObject['Country']}</span>
                  }
                </div>

                {/* Address */}
                <div className="floating-label-container">
                  <input
                    id="Address"
                    type="text"
                    name="Address"
                    autoComplete='address-line1'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="address" className='floating-label'>{getLocalization("ADDRESS")}</label>
                  {errors.includes('Address') &&
                    <span class="text-sm text-red-600">{errorsObject['Address']}</span>
                  }
                </div>

                {/* Address 2 */}
                <div className="floating-label-container">
                  <input
                    id="Address2"
                    type="text"
                    name="Address2"
                    autoComplete='address-line2'
                    placeholder=" "
                    className="floating-label-input"
                  />
                  <label for="Address2" className='floating-label'>{getLocalization("ADDRESS_2")}</label>
                  {errors.includes('Address2') &&
                    <span class="text-sm text-red-600">{errorsObject['Address2']}</span>
                  }
                </div>

                {/* City */}
                <div className="floating-label-container">
                  <input
                    id="City"
                    type="text"
                    name="City"
                    autoComplete='city'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="City" className='floating-label'>{getLocalization("CITY")}</label>
                  {errors.includes('City') &&
                    <span class="text-sm text-red-600">{errorsObject['City']}</span>
                  }
                </div>

                {/* State/Province */}
                <div className={classNames("floating-label-container transition-opacity", { '': states.length, 'cursor-not-allowed opacity-50': !states.length })}>
                  {/* <input
                  id="State"
                  type="text"
                  name="State"
                  autoComplete='address-level1'
                  placeholder=" "
                  required
                  className="floating-label-input"
                /> */}
                  <select
                    id="State"
                    name="State"
                    required
                    placeholder=" "
                    className="floating-label-input"
                    disabled={!states.length}
                  >
                    <option> </option>
                    {states.map((state) => (
                      <option value={state}>{state}</option>
                    ))}
                  </select>
                  <label for="state" className='floating-label'>{getLocalization("STATE_PROVINCE")}</label>
                  {errors.includes('State') &&
                    <span class="text-sm text-red-600">{errorsObject['State']}</span>
                  }
                </div>

                {/* Zip */}
                <div className="floating-label-container">
                  <input
                    id="Zip"
                    type="text"
                    name="Zip"
                    autoComplete='postal-code'
                    placeholder=" "
                    required
                    className="floating-label-input"
                  />
                  <label for="Zip" className='floating-label'>{getLocalization("POSTAL_CODE")}</label>
                  {errors.includes('Zip') &&
                    <span class="text-sm text-red-600">{errorsObject['Zip']}</span>
                  }
                </div>

              </div>

              <div className=''>
                <h2 className='subtitle mb-6 text-primary'>{getLocalization('PAYMENT_INFORMATION')}</h2>

                {/* Payment Information Section */}
                <div className=''>

                  {/* Card Number */}
                  <div className="floating-label-container">
                    <input
                      id="CardNumber"
                      type="text"
                      name="CardNumber"
                      autoComplete='cc-number'
                      placeholder=" "
                      required
                      className="floating-label-input"
                    />
                    <label for="CardNumber" className='floating-label'>{getLocalization("CARD_NUMBER")}</label>
                    {errors.includes('CardNumber') &&
                      <span class="text-sm text-red-600">{errorsObject['CardNumber']}</span>
                    }
                  </div>

                  {/* Expiration month/year */}
                  <div className='flex gap-4 my-6'>

                    {/* Expiration Month */}
                    <div className="floating-label-container cc">
                      <select
                        id="ExpirationMonth"
                        type="text"
                        name="ExpirationMonth"
                        autoComplete='cc-exp-month'
                        placeholder=" "
                        required
                        className="floating-label-input"
                      >
                        <option> </option>
                        <option key="1" value="1">01 - {getLocalization("JANUARY")}</option>
                        <option key="2" value="2">02 - {getLocalization("FEBRUARY")}</option>
                        <option key="3" value="3">03 - {getLocalization("MARCH")}</option>
                        <option key="4" value="4">04 - {getLocalization("APRIL")}</option>
                        <option key="5" value="5">05 - {getLocalization("MAY")}</option>
                        <option key="6" value="6">06 - {getLocalization("JUNE")}</option>
                        <option key="7" value="7">07 - {getLocalization("JULY")}</option>
                        <option key="8" value="8">08 - {getLocalization("AUGUST")}</option>
                        <option key="9" value="9">09 - {getLocalization("SEPTEMBER")}</option>
                        <option key="10" value="10">10 - {getLocalization("OCTOBER")}</option>
                        <option key="11" value="11">11 - {getLocalization("NOVEMBER")}</option>
                        <option key="12" value="12">12 - {getLocalization("DECEMBER")}</option>
                      </select>
                      <label for="ExpirationMonth" className='floating-label'>{getLocalization("EXPIRATION_MONTH")}</label>
                      {errors.includes('ExpirationMonth') &&
                        <span class="text-sm text-red-600">{errorsObject['ExpirationMonth']}</span>
                      }
                    </div>

                    {/* Expiration Year */}
                    <div className="floating-label-container flex gap-4 cc">
                      <select
                        id="ExpirationYear"
                        type="text"
                        name="ExpirationYear"
                        autoComplete='cc-exp-year'
                        placeholder=" "
                        required
                        className="floating-label-input w-50"
                      >
                        <option> </option>
                        {Array.from(Array(20)).map((val, i) => <option key={currentYear + i} value={currentYear + i}>{currentYear + i}</option>)}
                      </select>
                      <label for="ExpirationYear" className='floating-label'>{getLocalization("EXPIRATION_Year")}</label>
                      {errors.includes('ExpirationYear') &&
                        <span class="text-sm text-red-600">{errorsObject['ExpirationYear']}</span>
                      }
                    </div>
                  </div>

                  {/* CVV */}
                  <div className="floating-label-container">
                    <input
                      id="Cvv2"
                      type="text"
                      name="Cvv2"
                      autoComplete='cc-csc'
                      placeholder=" "
                      required
                      className="floating-label-input"
                    />
                    <label for="Cvv2" className='floating-label'>{getLocalization("CVV")}</label>
                    {errors.includes('Cvv2') &&
                      <span class="text-sm text-red-600">{errorsObject['Cvv2']}</span>
                    }
                  </div>

                </div>
              </div>

              {/* Order total */}
              <div className=''>
                <div className='subtitle text-primary mb-4'>{getLocalization("ORDER_TOTAL")}</div>
                <p><span className='text-4xl'>{currency}{price.toFixed(2)}</span><span className='ml-2'>{getLocalization("PER_YEAR")}</span></p>
                <p className='prose text-gray my-4'>
                  <Markdown components={{
                    p: ({ node, ...props }) => (
                      <p className='' {...props}>{props.children}</p>
                    ),
                  }}>{data.termsAndConditions}</Markdown>
                </p>

                <div className='mb-5 text-gray'>{getLocalization('REQUIRED_FIELD')}</div>

                <button type='submit' disabled={!valid || isLoading} className={classNames(getButtonClassNames('dark'),
                  {
                    'is-loading': isLoading
                  })}>{getLocalization('SUBMIT')}</button>
              </div>
            </div>
          </form>
        </>
        :
        <Markdown className='prose text-xl'>{data.thankYouMessage}</Markdown>
      }

    </section >
  );
};

SignUp.propTypes = {
}

export default SignUp
