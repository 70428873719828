import React from "react"
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { buttonLinkPropTypes } from '@/utils/types'
import Loader from '@/components/icons/loader'

export function getButtonClassNames(appearance, compact = false) {
  return classNames(
    // Common classes
    "w-auto rounded-full text-center tracking-wide border-2 transition-colors text-lg disabled:opacity-75 disabled:cursor-not-allowed",
    // Full-size button
    {
      "px-14 py-2 text-xl": compact === false,
    },
    // Compact button
    {
      "px-4 py-1": compact === true,
    },
    // Specific to when the button is primary
    {
      "bg-primary disabled:bg-primary text-white border-primary disabled:border-primary hover:bg-primary-buttonHovered hover:border-primary-buttonHovered": appearance === "dark",
    },
    // Specific to when the button is fully dark
    {
      "bg-secondary disabled:bg-secondary text-white border-secondary disabled:border-secondary hover:bg-secondary-buttonHovered hover:border-secondary-buttonHovered": appearance === "dark-secondary",
    },
    // Specific to when the button is dark outlines
    {
      "text-primary border-primary transition-colors hover:text-white hover:bg-primary": appearance === "dark-outline",
    },
    // Specific to when the button is fully white
    {
      "bg-white text-primary border-white": appearance === "white",
    },
    // Specific to when the button is white outlines
    {
      "text-white border-white hover:text-white hover:bg-primary": appearance === "white-outline",
    },
    // Ghost
    {
      'bg-transparent hover:bg-primary border-primary hover:text-white': appearance === 'ghost',
    },
    // Ghost outline
    {
      'bg-transparent border-white hover:bg-white hover:text-primary': appearance === 'ghost-outline',
    },
  )
}

const Button = ({
  button,
  appearance,
  compact = false,
  handleClick,
  loading = false,
  className = '',
  tabIndex = 0,
  type,
}) => {
  return (
    <button onClick={handleClick} type={type} className={getButtonClassNames(appearance, compact).concat(className ? ` ${className}` : '')} tabIndex={tabIndex}>
      {loading && <Loader />}
      {button.text}
    </button>
  )
}

Button.propTypes = {
  button: buttonLinkPropTypes,
  appearance: PropTypes.oneOf([
    'dark',
    'white-outline',
    'white',
    'dark-outline',
    'ghost',
    'ghost-outline',
  ]),
  compact: PropTypes.bool,
}

export default Button
