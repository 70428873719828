import React, { useState, useLayoutEffect, useCallback, useRef } from "react"
import Markdown from "@/utils/markdown";
import { getLocalizationKey } from "../utils/localize";
import { postFormDataAsJson } from '@/utils/api';
import classNames from "classnames";
import { getButtonClassNames } from "@/components/elements/button";

const FreeTrial = ({ data, pageContext }) => {

  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  const [isLoading, setIsLoading] = useState(false);
  // Form field errors
  const [errors, setErrors] = useState([]);
  // Generic form errors
  const [genericError, setGenericError] = useState('');
  const [token, setToken] = useState(null);

  const formRef = useRef(null);

  // Form is valid on front end
  const [valid, setValid] = useState(false);

  // Form was sent successfully
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChallenge = useCallback((event) => {
    event.preventDefault();
    grecaptcha.execute('6LeqAj8dAAAAAIh76lPLslMIooHkY7NxNVsIwMUS', { action: 'submit' }).then(token => {
      setToken(token);
      handleSubmit();
    })
  }, [setIsLoading]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const form = formRef.current;

    const url = form.action;

    try {
      const formData = new FormData(form);

      const responseData = await postFormDataAsJson({ url, formData });

      if (responseData) {
        setSubmitSuccess(true);
      }

    }

    catch (error) {
      if (error.message === 'Email Exists') {
        setGenericError('EMAIL_EXISTS');
      }
      const errors = JSON.parse(error.message);
      if (Array.isArray(errors)) {
        setErrors(JSON.parse(error.message));
      }
    }

    finally {
      setIsLoading(false);
    }
  }, [formRef, setErrors, setIsLoading, setGenericError, setSubmitSuccess]);

  useLayoutEffect(() => {
    if (submitSuccess) {
      document.documentElement.scrollTop = 0;
    }
  }, [submitSuccess]);

  return (
    <section className='container flex flex-col py-8 lg:py-12'>
      {!submitSuccess ?
        <>
          <p className='pb-4 lg:pb-8'>{getLocalization('FREE_TRIAL_FORM_DESCRIPTION')}</p>
          {genericError &&
            <span class="text-sm text-red-600 mb-8">{getLocalization(genericError)}</span>
          }
          {/* Form */}
          <div className='flex flex-col justify-start lg:justify-between lg:items-center gap-10 lg:flex-row'>
            <form action={'/api/free-trial'} ref={formRef} onSubmit={handleChallenge} onChange={(ev) => setValid(ev.currentTarget.checkValidity())} className='lg:w-128 max-w-md lg:max-w-none'>
              {/* First name */}
              <div class="floating-label-container">
                <input
                  type="text"
                  name="FirstName"
                  id="FirstName"
                  required
                  autoComplete='given-name'
                  placeholder=" "
                  className="floating-label-input"
                  onChange={() => { if (errors.includes('FirstName')) setErrors([errors].filter(e => e !== 'FirstName')) }}
                />
                <label htmlFor="FirstName" className='floating-label'>{getLocalization('FIRST_NAME')}</label>
                {errors.includes('FirstName') &&
                  <span class="text-sm text-red-600">{getLocalization('FIRST_NAME')} {getLocalization('IS_INVALID')}</span>
                }
              </div>

              {/* Last name */}
              <div className="floating-label-container">
                <input
                  type="text"
                  name="LastName"
                  id="LastName"
                  required
                  autoComplete='family-name'
                  placeholder=" "
                  className="floating-label-input"
                  onChange={() => { if (errors.includes('LastName')) setErrors([errors].filter(e => e !== 'LastName')) }}
                />
                <label htmlFor="LastName" className='floating-label'>{getLocalization('LAST_NAME')}</label>
                {errors.includes('LastName') &&
                  <span class="text-sm text-red-600">{getLocalization('LAST_NAME')} {getLocalization('IS_INVALID')}</span>
                }
              </div>

              {/* Email */}
              <div className="floating-label-container">
                <input
                  type="email"
                  name="Email"
                  id="Email"
                  autoComplete='email'
                  placeholder=" "
                  required
                  className="floating-label-input"
                  onChange={() => { if (errors.includes('Email')) setErrors([errors].filter(e => e !== 'Email')) }}
                />
                <label htmlFor="Email" className='floating-label'>{getLocalization('BUSINESS_EMAIL')}</label>
                {errors.includes('Email') &&
                  <span class="text-sm text-red-600">{getLocalization('BUSINESS_EMAIL')} {getLocalization('IS_INVALID')}</span>
                }
              </div>

              {/* Company */}
              <div className="floating-label-container">
                <input
                  type="text"
                  name="Company"
                  id="Company"
                  placeholder=" "
                  className="floating-label-input"
                  onChange={() => { if (errors.includes('Company')) setErrors([errors].filter(e => e !== 'Company')) }}
                />
                <label htmlFor="Company" className='floating-label'>{getLocalization('COMPANY')}</label>
                {errors.includes('Company') &&
                  <span class="text-sm text-red-600">{getLocalization('COMPANY')} {getLocalization('IS_INVALID')}</span>
                }
              </div>

              {/* Terms of use */}
              <div className='floating-label-container checkbox'>
                <input
                  name='accept'
                  id='accept'
                  type='checkbox'
                  value={true}
                  required
                  className='cursor-pointer'
                />
                <label htmlFor="accept" className='text-gray prose text-xl cursor-pointer flex items-center justify-start'>
                  <Markdown components={{
                    p: ({ node, ...props }) => (
                      <p className='' {...props}>{props.children}</p>
                    ),
                    a: ({ node, ...props }) => (
                      <a href={props.href.replace('/en', '')}>{props.children}</a>
                    ),
                  }}>{data.termsAndConditions}</Markdown>
                </label>
              </div>

              <div className='mb-5 text-gray'>{getLocalization('REQUIRED_FIELD')}</div>

              <button type='submit' disabled={!valid || isLoading} className={classNames(getButtonClassNames('dark'),
                {
                  'is-loading': isLoading
                })}>{getLocalization('SUBMIT')}
              </button>

              <input type="hidden" name="Token" value={token} />

            </form>
          </div>
        </>
        :
        <Markdown className='prose'>{data.thankYouMessage}</Markdown>
      }
    </section >
  );
};

FreeTrial.propTypes = {
}

export default FreeTrial
