import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import Markdown from "@/utils/markdown";
import ButtonLink from "../elements/button-link"
import Image from "../image"
import { MdCheck } from "react-icons/md"
import { getButtonAppearance } from "@/utils/button"
import classNames from 'classnames';
import lottie from 'lottie-web';

const Hero = ({ data }) => {

  // The bullet to be highlighted to match image
  const [step, setStep] = useState(0);

  const [animationLanguage] = useState(data.animationLanguage);

  const transitionDelay = 500;

  const videoRef = useRef(null);
  const animationInstance = useRef(null);

  useLayoutEffect(() => {
    let heroType;
    switch (animationLanguage) {
      case 'English':
        heroType = 'en';
        break;
      default:
        heroType = 'localized';
    }
    if (!animationInstance.current) {
      animationInstance.current = (lottie.loadAnimation({
        container: videoRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: `/home-hero-${heroType}/data.json`
      }));
    }
  }, [animationLanguage]);

  useLayoutEffect(() => {
    const intervalSpeed = 250;

    if (data.bullets.length && animationInstance.current) {
      let nextStep = step === data.bullets.length - 1 ? 0 : step + 1;
      let interval = setInterval(() => {
        if (nextStep === 0) {
          if ((animationInstance.current.currentFrame / animationInstance.current.frameRate) < data.bullets[step]['frames_seconds'] - (transitionDelay / 1000)) {
            setStep(nextStep);
          }
        }
        else if ((animationInstance.current.currentFrame / animationInstance.current.frameRate) >= Math.max(0, data.bullets[nextStep]['frames_seconds'] - (transitionDelay / 1000))) {
          setStep(nextStep);
        }
      }, intervalSpeed)

      return () => {
        clearInterval(interval)
      }
    }
  }, [step, setStep, animationInstance, videoRef]);

  return (
    <main className='bg-primary'>
      <div className="container flex flex-col lg:flex-row items-center justify-between bg-primary lg:pr-0 text-white font-100">
        {/* Right column for the image */}
        {data.picture.mime.includes('image') &&
          <Image
            media={data.picture}
            className="hidden lg:block lg:order-1 flex-shrink-0 object-contain w-full lg:w-6/12 mt-6 md:mt-0"
          />
        }
        {/* {data.picture.mime.includes('video') &&
          <Video
            media={data.picture}
            controls={false}
            autoPlay={true}
            ref={videoRef}
            loop
            className="hidden lg:block lg:order-1 flex-shrink-0 object-contain w-full lg:w-6/12 mt-6 md:mt-0"
          />
        } */}
        <div id='home-hero-animation' className="hidden lg:block lg:order-1 flex-shrink-0 object-contain w-full lg:w-6/12 mt-6 md:mt-0" ref={videoRef}></div>

        {/* Left column for content */}
        <div className="flex-1 lg:pr-8 py-8 lg:py-20">
          {/* Hero section label */}
          <p className="uppercase tracking-wide">{data.label}</p>
          {/* Big title */}
          <h1 className="title mb-6">{data.title}</h1>
          {/* Description paragraph */}
          <h2 className="text-xl mb-6">{data.description}</h2>
          {/* Bullts */}
          <ul>
            {data.bullets.map((bullet, i) => {
              return <li className={classNames(`text-2xl opacity:100 transition-opacity duration-${transitionDelay} flex align-baseline mb-2`,
                { 'lg:opacity-70': step !== i },
                { [`lg:opacity-100 delay-${transitionDelay}`]: step === i },
              )}>
                <MdCheck className={classNames(`inline transition-opacity duration-${transitionDelay} stroke-2 mr-2 mt-0.5`,
                  { 'opacity-100 lg:opacity-0': step !== i },
                  { [`opacity-100 delay-${transitionDelay}`]: step === i }
                )}
                />
                {bullet.text}
              </li>
            })}
          </ul>
          {/* Buttons row */}
          <div className="flex flex-row flex-wrap gap-4 mt-6">
            {data.buttons.map(button => (
              <ButtonLink
                button={button}
                appearance={getButtonAppearance(button.type, 'primary')}
                key={button.id}
              />
            ))}
          </div>
          {/* Small rich text */}
          <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
            <Markdown>{data.smallTextWithLink}</Markdown>
          </div>
        </div>
      </div>
    </main >
  )
}

export default Hero
