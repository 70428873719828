import React from "react"

const LargeVideoLink = ({ data }) => {
  return (
    <section className="container flex flex-col align-middle text-center py-8 lg:py-12">
      {/* Video wrapper */}
      <div className="w-full lg:w-9/12 mx-auto overflow-hidden shadow-2xl" dangerouslySetInnerHTML={{ __html: data.embed }} />
    </section>
  );
};

export default LargeVideoLink;
