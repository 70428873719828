
import React, { useLayoutEffect, useCallback, useRef, useState, useEffect } from "react"
import PropTypes, { string } from "prop-types"
import { getLocalizationKey } from "../utils/localize";
import Markdown from "@/utils/markdown";
import { postFormDataAsJson } from '@/utils/api';
import classNames from "classnames";
import { getButtonClassNames } from "@/components/elements/button";
import { useCookies } from 'react-cookie';

const ContactUs = ({ data, pageContext }) => {
  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  const [cookies, setCookie, removeCookie] = useCookies();

  const [isLoading, setIsLoading] = useState(false);
  // Form field errors
  const [errors, setErrors] = useState([]);
  // Generic form errors
  const [genericError, setGenericError] = useState('');
  const [token, setToken] = useState(null);

  const formRef = useRef(null);
  const messageRef = useRef(null);

  // Form is valid on front end
  const [valid, setValid] = useState(false);

  // Form was sent successfully
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChallenge = useCallback((event) => {
    event.preventDefault();
    grecaptcha.execute('6LeqAj8dAAAAAIh76lPLslMIooHkY7NxNVsIwMUS', { action: 'submit' }).then(token => {
      setToken(token);
      handleSubmit();
    })
  }, [setIsLoading]);


  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const form = formRef.current;

    const url = form.action;

    try {
      const formData = new FormData(form);

      const responseData = await postFormDataAsJson({ url, formData });

      if (responseData) {
        setSubmitSuccess(true);
      }

    }

    catch (error) {
      const errors = JSON.parse(error.message);
      if (Array.isArray(errors)) {
        setErrors(JSON.parse(error.message));
      }
    }

    finally {
      setIsLoading(false);
    }
  }, [formRef, setErrors, setIsLoading, setGenericError, setSubmitSuccess]);

  const renderRegionOptions = () => {
    return [
      {
        text: 'USA and Canada'
      },
      {
        text: 'Africa'
      },
      {
        text: 'Asia & Pacific Rim'
      },
      {
        text: 'Australia & New Zealand'
      },
      {
        text: 'Europe'
      },
      {
        text: 'India'
      },
      {
        text: 'Mexico and Central America'
      },
      {
        text: 'Middle East'
      },
      {
        text: 'Russia'
      },
      {
        text: 'Scandinavia'
      },
      {
        text: 'South America'
      },
    ].map(region => <option>{region.text}</option>)
  }

  useEffect(() => {
    if (messageRef.current && cookies['prefillMessage']) {
      messageRef.current.innerText = cookies['prefillMessage'];
      removeCookie('prefillMessage');
    }
  }, [cookies, removeCookie, messageRef])

  useLayoutEffect(() => {
    if (submitSuccess || genericError) {
      document.documentElement.scrollTop = 0;
    }
  }, [submitSuccess || genericError]);

  return (
    <section className='container flex flex-col lg:flex-row items-start gap-8 lg:gap-12 py-8 lg:py-12'>
      {/* Form */}
      <div className='flex flex-col justify-start md:justify-between md:items-center gap-10 lg:flex-row w-full lg:w-auto md:max-w-md lg:max-w-none'>
        {genericError &&
          <span class="text-sm text-red-600 mb-8">{getLocalization(genericError)}</span>
        }
        {!submitSuccess ?
          <form action={'/api/contact-us'} ref={formRef} onChange={(ev) => setValid(ev.currentTarget.checkValidity())} onSubmit={handleChallenge} className='w-96 lg:w-128'>
            {/* First name */}
            <div class="floating-label-container">
              <input
                type="text"
                name="FirstName"
                id="FirstName"
                autoComplete='given-name'
                placeholder=" "
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('FirstName')) setErrors([errors].filter(e => e !== 'FirstName')) }}
              />
              <label htmlFor="FirstName" className='floating-label'>{getLocalization('FIRST_NAME')}</label>
              {errors.includes('firstname') &&
                <span class="text-sm text-red-600">{getLocalization('FIRST_NAME')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Last name */}
            <div className="floating-label-container">
              <input
                type="text"
                name="LastName"
                id="LastName"
                autoComplete='family-name'
                placeholder=" "
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('LastName')) setErrors([errors].filter(e => e !== 'LastName')) }}
              />
              <label htmlFor="LastName" className='floating-label'>{getLocalization('LAST_NAME')}</label>
              {errors.includes('lastname') &&
                <span class="text-sm text-red-600">{getLocalization('LAST_NAME')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Email */}
            <div className="floating-label-container">
              <input
                type="email"
                name="Email"
                id="Email"
                autoComplete='email'
                placeholder=" "
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('Email')) setErrors([errors].filter(e => e !== 'Email')) }}
              />
              <label htmlFor="Email" className='floating-label'>{getLocalization('EMAIL')}</label>
              {errors.includes('Email') &&
                <span class="text-sm text-red-600">{getLocalization('EMAIL')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Company */}
            <div className="floating-label-container">
              <input
                type="text"
                name="Company"
                id="Company"
                placeholder=" "
                className="floating-label-input"
                onChange={() => { if (errors.includes('Company')) setErrors([errors].filter(e => e !== 'Company')) }}
              />
              <label htmlFor="Company" className='floating-label'>{getLocalization('COMPANY')}</label>
              {errors.includes('Company') &&
                <span class="text-sm text-red-600">{getLocalization('COMPANY')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Phone */}
            <div className="floating-label-container">
              <input
                type="tel"
                name="Phone"
                id="Phone"
                placeholder=" "
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('Phone')) setErrors([errors].filter(e => e !== 'Phone')) }}
              />
              <label htmlFor="Phone" className='floating-label'>{getLocalization('PHONE')}</label>
              {errors.includes('Phone') &&
                <span class="text-sm text-red-600">{getLocalization('PHONE')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Message */}
            <div className="floating-label-container">
              <textarea
                type="text"
                name="Message"
                id="Message"
                placeholder=" "
                ref={messageRef}
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('Message')) setErrors([errors].filter(e => e !== 'Message')) }}
              />
              <label htmlFor="Message" className='floating-label'>{getLocalization('MESSAGE')}</label>
              {errors.includes('Message') &&
                <span class="text-sm text-red-600">{getLocalization('MESSAGE')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Region */}
            <div className="floating-label-container">
              <select id='Region' name='Region' placeholder=' ' required className='floating-label-input'
                onChange={() => { if (errors.includes('Region')) setErrors([errors].filter(e => e !== 'Region')) }}
              >
                <option> </option>
                {renderRegionOptions()}
              </select>
              <label htmlFor="Region" className='floating-label'>{getLocalization('YOUR_REGION')}</label>
              {errors.includes('Region') &&
                <span class="text-sm text-red-600">{getLocalization('YOUR_REGION')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Need Software Support */}
            <div className='floating-label-container checkbox'>
              <input
                id='NeedsSupport'
                name='NeedsSupport'
                type='checkbox'
                value={true}
                className='cursor-pointer'
              />
              <label className='text-gray cursor-pointer' htmlFor='NeedsSupport'>{getLocalization('SUPPORT_REQUESTED')}</label>
            </div>

            <div className='mb-5 text-gray'>{getLocalization('REQUIRED_FIELD')}</div>

            <button type='submit' disabled={!valid || isLoading} className={classNames(getButtonClassNames('dark'),
              {
                'is-loading': isLoading
              })}>{getLocalization('SUBMIT')}</button>

            <input type="hidden" name="Token" value={token} />

          </form>

          :
          <Markdown className='prose text-2xl'>{data.confirmationMessage}</Markdown>
        }
      </div>

      <div>
        <Markdown className='prose'>{data.contactInformation}</Markdown>
      </div>
    </section>
  );
};

ContactUs.propTypes = {
  contactUs: PropTypes.shape({
    contactInformation: string,
  })
}

export default ContactUs
