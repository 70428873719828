import React from "react"
import PropTypes from "prop-types";
import Markdown from "@/utils/markdown";

const RichText = ({ data }) => {
  return (
    <section className='container py-8 lg:py-12'>
      <div className="prose max-w-none prose-lg container p-0">
        <Markdown>{data.content}</Markdown>
      </div>
    </section>
  );
};

RichText.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
  }),
};

export default RichText;
