import React from "react"
import ButtonLink from "../elements/button-link";
import { getButtonAppearance } from "../../utils/button";
import { MdCheck } from "react-icons/md";
import classNames from "classnames";

const renderListItem = (features, className) => {
  return <li key={'container' + features[0]}>
    <ul>
      {features.map((feature) => (

        <li key={feature} className={classNames('text-xl flex mb-3', className)}><MdCheck className='flex-shrink-0 inline stroke-2 mr-2 mt-0.5' />{feature}</li>
      ))}
    </ul>
  </li>
}

const FeatureColumnsGroup = ({ data }) => {
  const a1 = [];
  const a2 = [];
  const a3 = [];

  data.FeatureRows.map((row) => {
    a1.push(row.feature1)
    a2.push(row.feature2)
    a3.push(row.feature3)
  });

  return (
    <section className='bg-primary my-8 lg:my-12'>
      <div className="text-white container flex flex-col gap-8 lg:gap-12 align-top py-8 lg:py-12">
        <h2 className='title'>{data.title}</h2>
        <ul className='grid grid-cols-1 md:grid-cols-3 justify-items-start md:justify-items-center xl:justify-items-start'>
          {renderListItem(a1)}
          {renderListItem(a2)}
          {renderListItem(a3)}
        </ul>
        <div className="flex flex-row justify-start flex-wrap gap-4">
          <ButtonLink
            button={data.button}
            appearance={getButtonAppearance(data.button.type, "primary")}
          />
        </div>
      </div>
    </section>
  );
};

export default FeatureColumnsGroup;
