import React, { useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"
import { mediaPropTypes } from "../utils/types";
import Image from "../components/image";
// import { getTimeZones } from '@vvo/tzdb';
import Markdown from "@/utils/markdown";
import { getLocalizationKey } from "../utils/localize";
import { postFormDataAsJson } from '@/utils/api';
import classNames from "classnames";
import { getButtonClassNames } from "@/components/elements/button";

const ScheduleDemo = ({ scheduleDemo, pageContext }) => {
  // const timezones = getTimeZones();

  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  const [isLoading, setIsLoading] = useState(false);
  // Form field errors
  const [errors, setErrors] = useState([]);
  // Generic form errors
  const [genericError, setGenericError] = useState('');
  const [token, setToken] = useState(null);

  const formRef = useRef(null);

  // Form is valid on front end
  const [valid, setValid] = useState(false);

  // Form was sent successfully
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChallenge = useCallback((event) => {
    event.preventDefault();
    grecaptcha.execute('6LeqAj8dAAAAAIh76lPLslMIooHkY7NxNVsIwMUS', { action: 'submit' }).then(token => {
      setToken(token);
      handleSubmit();
    })
  }, [setIsLoading]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const form = formRef.current;

    const url = form.action;

    try {
      const formData = new FormData(form);

      const responseData = await postFormDataAsJson({ url, formData });

      if (responseData) {
        setSubmitSuccess(true);
      }
    }

    catch (error) {
      console.error(error);
      const errors = JSON.parse(error.message);
      if (Array.isArray(errors)) {
        setErrors(JSON.parse(error.message));
      }
    }

    finally {
      setIsLoading(false);
    }
  }, [formRef, setErrors, setIsLoading, setGenericError, setSubmitSuccess]);

  return (
    <section className='container flex flex-col gap-12 py-8 lg:py-12'>
      <div className='flex flex-col justify-start md:justify-between md:items-center gap-10 lg:flex-row'>
        {genericError &&
          <span class="text-sm text-red-600 mb-8">{getLocalization(genericError)}</span>
        }
        {!submitSuccess ?
          <form action={'/api/schedule-demo'} onChange={(ev) => setValid(ev.currentTarget.checkValidity())} className='md:w-112 lg:w-128 flex-shrink-0' onSubmit={handleChallenge} ref={formRef}>
            <h3 className='title mb-6 text-primary'>{getLocalization('SCHEDULE_A_DEMO')}</h3>
            {/* First name */}
            <div class="floating-label-container">
              <input
                type="text"
                name="FirstName"
                id="FirstName"
                autoComplete='given-name'
                placeholder=" "
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('FirstName')) setErrors([errors].filter(e => e !== 'FirstName')) }}
              />
              <label htmlFor="FirstName" className='floating-label'>{getLocalization('FIRST_NAME')}</label>
              {errors.includes('FirstName') &&
                <span class="text-sm text-red-600">{getLocalization('FIRST_NAME')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Last name */}
            <div className="floating-label-container">
              <input
                type="text"
                name="LastName"
                id="LastName"
                autoComplete='family-name'
                placeholder=" "
                required
                className="floating-label-input"
                onChange={() => { if (errors.includes('LastName')) setErrors([errors].filter(e => e !== 'LastName')) }}
              />
              <label htmlFor="LastName" className='floating-label'>{getLocalization('LAST_NAME')}</label>
              {errors.includes('LastName') &&
                <span class="text-sm text-red-600">{getLocalization('LAST_NAME')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Email */}
            <div className="floating-label-container">
              <input
                type="email"
                name="Email"
                id="Email"
                autoComplete='email'
                placeholder=" "
                required
                className="floating-label-input"
              />
              <label htmlFor="Email" className='floating-label'>{getLocalization('BUSINESS_EMAIL')}</label>
              {errors.includes('Email') &&
                <span class="text-sm text-red-600">{getLocalization('BUSINESS_EMAIL')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Timezone */}
            <div className="floating-label-container">
              {/* <select
              name="timezone"
              placeholder=" "
              required
              className="floating-label-input"
            >
              <option> </option>
            {timezones.map(tz => <option>{tz.rawFormat}</option>)}
          </select> */}
              <input
                type="text"
                name="Timezone"
                id="Timezone"
                placeholder=" "
                required
                className="floating-label-input"
              />
              <label htmlFor="Timezone" className='floating-label'>{getLocalization('YOUR_TIMEZONE')}</label>
              {errors.includes('Timezone') &&
                <span class="text-sm text-red-600">{getLocalization('YOUR_TIMEZONE')} {getLocalization('IS_INVALID')}</span>
              }
            </div>

            {/* Preferred meeting times */}
            <div className="floating-label-container">
              <input
                type="text"
                name="PreferredTimes"
                id="PreferredTimes"
                placeholder=" "
                required
                className="floating-label-input"
              />
              <label htmlFor="PreferredTimes" className='floating-label'>{getLocalization('PREFERRED_MEETING_TIMES')}</label>
              {errors.includes('PreferredTimes') &&
                <span class="text-sm text-red-600">{getLocalization('PREFERRED_MEETING_TIMES')} {getLocalization('IS_INVALID')}</span>
              }
            </div>
            {/* Promotions */}
            <div className='floating-label-container checkbox'>
              <input
                name='ContactAboutNews'
                id='ContactAboutNews'
                type='checkbox'
                className='cursor-pointer -mt-1'
                value={true}
              />
              <label htmlFor='ContactAboutNews' className='text-gray cursor-pointer'>{getLocalization('RECEIVE_PROMOTIONS')}</label>
            </div>

            <div className='mb-5 text-gray'>{getLocalization('REQUIRED_FIELD')}</div>

            <button type='submit' disabled={!valid || isLoading} className={classNames(getButtonClassNames('dark'),
              {
                'is-loading': isLoading
              })}>{getLocalization('SUBMIT')}</button>

            <input type="hidden" name="Token" value={token} />

          </form>

          :
          <Markdown className='prose text-2xl'>{scheduleDemo.thankYouMessage}</Markdown>
        }
        {/* Image */}
        <div className='hidden lg:block object-contain lg:w-5/12'>
          <Image
            className=''
            placeholder="none"
            media={scheduleDemo.image} />
        </div>
      </div>
    </section >
  );
};

ScheduleDemo.propTypes = {
  scheduleDemo: PropTypes.shape({
    image: mediaPropTypes
  })
}

export default ScheduleDemo
