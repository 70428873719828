import React, { useReducer, useCallback } from "react"
import { MdCheck } from "react-icons/md";
import classNames from "classnames";
import ButtonLink from "../elements/button-link";
import { getButtonAppearance } from "@/utils/button"
import { getLocalizationKey } from "@/utils/localize";
import { useCookies } from "react-cookie";

const Pricing = ({ data, pageContext, pricing }) => {
  const [cookies, setCookie] = useCookies();

  pricing = pricing.pricing;

  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  const initState = pricing.plans.filter((plan) => plan.priceRange.length).reduce((pv, cv) => {
    pv[cv.id] = {
      id: cv.id, count: cv.defaultRangeValue || cv.priceRange[0].count, values: cv.priceRange.reduce((pv, cv) => {
        pv[cv.count] = cv.amount;
        return pv;
      }, {})
    };
    return pv;
  }, {});

  const [ranges, dispatch] = useReducer((state, action) => {
    return {
      ...state,
      [action.id]: { ...state[action.id], count: action.count }
    }
  }, initState);

  const getClickFn = useCallback((index) => {
    // Individiual
    if (index === 0) {
      return;
    }
    if (index === 1) {
      setCookie('prefillMessage', getLocalization('CONTACT_PREFILL_TEAM').replace('$TOKEN', ranges[pricing.plans[1].id].count));
      return;
    }
    if (index === 2) {
      setCookie('prefillMessage', getLocalization('CONTACT_PREFILL_ENTERPRISE'));
      return;
    }
  }, [setCookie, ranges]);

  return (
    <div className="container py-8 lg:py-12 flex flex-col items-center">
      <h3 className="title mb-6 text-primary">{pricing.title}</h3>
      <div className="price-grid grid grid-cols-1 lg:grid-cols-3 lg:auto-rows-max text-white lg:flex-row gap-4 lg:justify-center md:w-6/12 lg:w-full xl:w-11/12 2xl:w-9/12">
        {pricing.plans.map((plan, i) => (
          <>
            <div className='bg-gray flex-1 pb-16'>
              {/* Plan name & description */}
              <div
                className={classNames(
                  // Common
                  'p-4 pt-8',
                  // Normal plan
                  {
                    "bg-gray-dark text-white": !plan.isRecommended,
                  },
                  // Recommended plan
                  {
                    "bg-primary text-white border-primary":
                      plan.isRecommended,
                  }
                )}
                key={plan.id}
              >
                <h2 className="text-4xl">{plan.name}</h2>
                <p
                  className={classNames("mt-4 text-2xl", {
                    "text-white": plan.isRecommended,
                    "text-white": !plan.isRecommended,
                  })}
                >
                  {plan.priceRange.length ?
                    <div className='flex items-center pr-2'>
                      <span className='w-8 text-center'>{ranges[plan.id].count}</span><span className='mr-4'>{plan.licenseSize ? ` ${plan.licenseSize}` : ''}</span>
                      <input className='flex-grow' type='range' defaultValue={plan.defaultRangeValue !== null ? plan.defaultRangeValue : plan.priceRange[0].count} min={plan.priceRange[0].count} max={plan.priceRange[plan.priceRange.length - 1].count} onInput={(ev) => dispatch({ id: plan.id, count: ev.target.value })} />
                    </div>
                    :
                    plan.licenseSize
                  }
                </p>
              </div>

              {/* Price & features */}
              <div className='p-4'>
                <div className='lg:h-10 lg:flex lg:items-end lg:overflow-hidden'>
                  {plan.priceRange.length ?
                    <span className='text-4xl'>{`$${Number(ranges[plan.id].values[ranges[plan.id].count]).toLocaleString('en-US')}`}</span>
                    :
                    !plan.price ? <span className='text-xl'>{getLocalization('CONTACT_US')}</span> : <span className='text-4xl'>{`$${Number(plan.price).toLocaleString('en-US')}`}</span>
                  }
                  {plan.pricePeriod &&
                    <span className="ml-2 text-xl">{plan.pricePeriod}</span>
                  }
                </div>
                <div className='py-4 flex'>
                  <ButtonLink
                    button={plan.button}
                    onClickCapture={() => getClickFn(i)}
                    appearance={getButtonAppearance(plan.button.type, "light")}
                  />
                </div>
                <ul className="mt-4 flex flex-col border-gray-light border-t">
                  {plan.features.map((feature) => (
                    <li
                      className="flex flex-row justify-start items-center text-xl border-gray-light border-b py-2 m-0"
                      key={feature.id}
                    >
                      <MdCheck className="flex-shrink-0 h-6 w-auto text-white mr-2 stroke-2" />
                      <span className='px-4'>{feature.name}</span>
                    </li>
                  ))}
                </ul>
              </div>

            </div>

            {/* Mobile description */}
            <div className={classNames('lg:hidden flex flex-col max-w-sm text-white lg:flex-row gap-8 lg:justify-center px-4',
              {
                'mb-8': (i !== pricing.plans.length - 1)
              })}>
              <p className='text-xl text-gray'>{plan.description}</p>
            </div>
          </>
        ))}

        {/* Desktop description */}
        {pricing.plans.map((plan) => {
          return <div className='hidden lg:flex flex-col max-w-sm text-white lg:flex-row gap-8 lg:justify-center px-4'>
            <p className='text-xl text-gray'>{plan.description}</p>
          </div>
        })}

      </div>
    </div>
  );
};

export default Pricing;
