import React, { createRef, useEffect, useCallback, useRef, useState } from "react"
import ButtonLink from "../elements/button-link"
import Image from "../image"
import Video from '@/components/elements/video'
import { getButtonAppearance } from "@/utils/button"
import { useSprings, animated, config } from 'react-spring'
import Markdown from '@/utils/markdown'
import classNames from "classnames"
import Button from "../elements/button"
import { MdCheck } from "react-icons/md"

const Carousel = ({ data }) => {
  const [step, setStep] = useState(0);

  const [interacted, setInteracted] = useState(false);

  const videoRefs = useRef(data.items.map(() => ({ video: createRef(), viewed: createRef(false) })));

  const animationFn = useCallback((index) => {
    let pos = '0%';
    pos = `-${100 * (step)}%`;
    // if (step > index) pos = `${100 * (step)}%`;
    return ({
      transform: `translateX(${pos})`,
    })
  }, [step]);

  const [springs, api] = useSprings(data.items.length, index => ({ ...animationFn(index), config: { ...config.default, clamp: true } }))

  useEffect(() => {
    if (step !== 0 && !interacted) setInteracted(true)
  }, [step, interacted, setInteracted])

  useEffect(() => {
    api.start(index => animationFn(index))
  }, [step])

  useEffect(() => {
    if (interacted) {
      setTimeout(() => {
        if (step !== 0 && !videoRefs.current[step].viewed.current) {
          videoRefs.current[step].video.current.play()
          videoRefs.current[step].viewed.current = true;
        }
      }, 500)
    }
  }, [step, videoRefs, interacted])

  return (
    <section className='container flex flex-col items-center bg-white py-8 lg:py-12'>
      {/* Guide */}
      <div className='hidden lg:flex gap-6 lg:gap-4 pb-8 lg:pb-0 lg:pt-1 lg:mt-4 xl:mt-0 justify-center lg:order-2'>
        {data.items.map((carouselItem, index) => (
          <button onClick={() => setStep(index)} className={classNames(
            'rounded-full w-3 h-3 bg-gray-light transition-colors duration-500',
            { 'bg-primary shadow': step === index }
          )}></button>
        ))}
      </div>

      {/* Mobile */}
      <div className="flex lg:hidden flex-col gap-12 font-100 lg:order-1">
        {data.items.map((carouselItem, index) => (
          <div
            className={classNames(
              // Common classes
              "w-full z-10 flex flex-col justify-start md:justify-between md:items-center",
            )}
            key={carouselItem.id}
          >
            {/* Text section */}
            <div className={classNames("lg:order-2 lg:w-7/12 text-lg",
              {
                'lg:pl-6': (index % 2 === 0),
                'lg:pr-6': (index % 2 !== 0)
              })}>
              <h3 className="title mb-6 text-primary">{carouselItem.title}</h3>

              {/* Rich text */}
              <Markdown className='text-xl' components={{
                li: ({ node, ...props }) => (
                  <li className='flex text-xl mb-2' {...props}>
                    {props.ordered ?
                      <span className='mr-2'>{props.index + 1}. </span>
                      :
                      <MdCheck className='flex-shrink-0 inline text-primary stroke-2 mr-2 mt-0.5' />
                    }{props.children}</li>
                ),
                p: ({ node, ...props }) => (
                  <p className='mb-4' {...props}>{props.children}</p>
                ),
                a: ({ node, ...props }) => (
                  <a href={props.href.replace('/en', '')}>{props.children}</a>
                ),
              }}>{carouselItem.body}</Markdown>

              {((carouselItem.button && index === data.items.length - 1) || (carouselItem.linkButton && index === data.items.length - 1)) &&
                <div className="mt-4 mb-8 inline-flex flex-row justify-start flex-wrap gap-4">
                  {(carouselItem.button && index === data.items.length - 1) &&
                    <Button button={carouselItem.button} handleClick={() => setStep(index + 1)} appearance={getButtonAppearance(carouselItem.button.type, 'light')} tabIndex={step === index ? 0 : -1}>
                      {carouselItem.button.text}
                    </Button>
                  }
                  {(carouselItem.linkButton && index === data.items.length - 1) &&
                    <ButtonLink button={carouselItem.linkButton} appearance={getButtonAppearance(carouselItem.linkButton.type, 'light')} tabIndex={step === index ? 0 : -1}>
                      <div className="text-blue-600 with-arrow hover:underline">
                        {carouselItem.linkButton.text}
                      </div>
                    </ButtonLink>
                  }
                </div>
              }

            </div>

            {/* Media section */}
            <div className="w-full lg:order-1 sm:9/12 lg:w-5/12 max-h-full">
              {/* Images */}
              {carouselItem.media.mime.startsWith("image") && (
                <Image media={carouselItem.media} className="w-full h-auto" />
              )}
              {/* Videos */}
              {carouselItem.media.mime.startsWith("video") && (
                <Video
                  media={carouselItem.media}
                  className="w-full h-auto"
                  controls={false}
                  autoPlay={!interacted && step === 0}
                  ref={videoRefs.current[index].video}
                />
              )}
            </div>

          </div>
        ))}
      </div>

      {/* Desktop */}
      <div className="hidden lg:flex overflow-x-hidden lg:overflow-y-hidden relative items-center justify-between pr-0 font-100 lg:order-1">
        {data.items.map((carouselItem, index) => (
          <animated.div
            style={{ ...springs[index], flex: '0 0 auto' }}
            className={classNames(
              // Common classes
              "w-full z-10 flex flex-col justify-start md:justify-between md:items-center gap-10",
              {
                "lg:flex-row": (index % 2 === 0),
                "lg:flex-row-reverse": (index % 2 === 1),
              },
              {
                "": (step !== index)
              }
            )}
            key={carouselItem.id}
          >
            {/* Text section */}
            <div className={classNames("lg:order-2 lg:w-5/12 xl:max-w-2xl mr-auto text-lg",
              {
                'lg:pr-16': (index % 2 === 0),
                'lg:pl-16': (index % 2 !== 0)
              })}>
              <h3 className="title mb-6 text-primary">{carouselItem.title}</h3>
              {/* Rich text */}
              <Markdown className='text-xl' components={{
                li: ({ node, ...props }) => (
                  <li className='flex text-xl mb-2' {...props}>
                    {props.ordered ?
                      <span className='mr-2'>{props.index + 1}. </span>
                      :
                      <MdCheck className='flex-shrink-0 inline text-primary stroke-2 mr-2 mt-0.5' />
                    }{props.children}</li>
                ),
                p: ({ node, ...props }) => (
                  <p className='mb-4' {...props}>{props.children}</p>
                ),
                a: ({ node, ...props }) => (
                  <a href={props.href.replace('/en', '')}>{props.children}</a>
                ),
              }}>{carouselItem.body}</Markdown>
              <div className="mt-4 inline-flex flex-row justify-start flex-wrap gap-4">
                {carouselItem.button &&
                  <Button button={carouselItem.button} handleClick={() => setStep(index + 1)} appearance={getButtonAppearance(carouselItem.button.type, 'light')} tabIndex={step === index ? 0 : -1}>
                    {carouselItem.button.text}
                  </Button>
                }
                {carouselItem.linkButton &&
                  <ButtonLink button={carouselItem.linkButton} appearance={getButtonAppearance(carouselItem.linkButton.type, 'light')} tabIndex={step === index ? 0 : -1}>
                    <div className="text-blue-600 with-arrow hover:underline">
                      {carouselItem.linkButton.text}
                    </div>
                  </ButtonLink>
                }

              </div>
            </div>

            {/* Media section */}
            <div className={classNames("w-full lg:order-1 sm:9/12 lg:w-5/12 xl:w-5/12 2xl:w-4/12 mx-auto max-h-full",
              {
                'lg:w-6/12 xl:w-7/12 2xl:w-6/12': index === 0
              }
            )}>
              {/* Images */}
              {carouselItem.media.mime.startsWith("image") && (
                <Image media={carouselItem.media} className="w-full h-auto" />
              )}
              {/* Videos */}
              {carouselItem.media.mime.startsWith("video") && (
                <Video
                  media={carouselItem.media}
                  className="w-full h-auto"
                  controls={false}
                  autoPlay={!interacted && step === 0}
                  ref={videoRefs.current[index].video}
                />
              )}
            </div>

          </animated.div>
        ))}
      </div>
    </section>
  )
}

export default Carousel
