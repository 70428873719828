import React from "react"
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { mediaPropTypes } from "@/utils/types";
import { getStrapiMedia } from "@/utils/media";

const LargeText = ({ data }) => {
    return (
        <section className='text-white relative my-8 lg:my-12 bg-cover bg-center' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${getStrapiMedia(data.media.url)})` }}>
            <div className='container py-12 lg:py-18 flex flex-col justify-center items-start text-xl'>
                <div className='w-3/4'>
                    <ReactMarkdown parserOptions={{ commonmark: true }} components={{
                        p: ({ node, ...props }) => (
                            <p className='' {...props}>{props.children}</p>
                        ),
                        a: ({ node, ...props }) => (
                            <a href={props.href.replace('/en', '')}>{props.children}</a>
                        ),
                    }}>
                        {data.text}
                    </ReactMarkdown>
                </div>
            </div>
        </section>
    );
};

LargeText.propTypes = {
    text: PropTypes.string,
    media: mediaPropTypes.isRequired,
};

export default LargeText;