import React from "react"
import classNames from "classnames";
import Image from "../image";
import Video from "../elements/video";
import ButtonLink from "../elements/button-link";
import { MdCheck } from "react-icons/md";
import { getButtonAppearance } from "../../utils/button";

const BulletFeatureRowsGroup = ({ data }) => {
  const getImagePlacement = (index) => {
    if ((index % 2 === 0 && data.bulletLeadingImagePlacement === 'right') || (index % 2 === 1 && data.bulletLeadingImagePlacement === 'left')) {
      return 'right'
    }
    return 'left';
  }

  return (
    <div className={classNames("container flex flex-col gap-24 lg:gap-12 py-8 lg:py-12 lg:px-0 lg:max-w-none",
    )}>
      {data.features.map((feature, index) => (
        <div
          className={classNames(
            // Common classes
            "flex flex-col justify-start md:justify-between md:items-center overflow-hidden",
            {
              "lg:flex-row": getImagePlacement(index) === 'right',
              "lg:flex-row-reverse": getImagePlacement(index) === 'left',
            }
          )}
          key={feature.id}
        >

          {/* Text section */}
          <div className={classNames("feature-text w-full text-lg",
            {
              'right lg:p-0 lg:pl-16': getImagePlacement(index) === 'right',
              'left lg:p-0 lg:pr-16': getImagePlacement(index) === 'left',
            })}>

            <h3 className="title text-primary mb-6">{feature.title}</h3>

            {/* Mobile Media section */}
            <div className={classNames("lg:hidden w-full sm:9/12 lg:w-6/12 max-h-full relative",
              {
                "lg:-right-32": getImagePlacement(index) === 'right',
                "lg:-left-32": getImagePlacement(index) === 'left',
              }
            )}>
              {/* Images */}
              {feature.media.mime.startsWith("image") && (
                <Image media={feature.media} className="w-full h-auto" />
              )}
              {/* Videos */}
              {feature.media.mime.startsWith("video") && (
                <Video
                  media={feature.media}
                  className="w-full h-auto"
                  autoPlay
                  controls={false}
                />
              )}
            </div>

            <p className="text-xl">{feature.description}</p>

            {/* Bullets */}
            {feature.bullets &&
              <ul className='my-8'>
                {feature.bullets.map(bullet => {
                  return <li className='flex text-xl my-4'><MdCheck className='flex-shrink-0 inline text-primary stroke-2 mr-2 mt-0.5' />{bullet.text}</li>
                })}
              </ul>
            }

            {feature.button &&
              <div className="mt-4 flex flex-row justify-start flex-wrap gap-4">
                <ButtonLink button={feature.button} appearance={getButtonAppearance(feature.button.type, 'light')}>
                  <div className="text-blue-600 with-arrow hover:underline">
                    {feature.button.text}
                  </div>
                </ButtonLink>
              </div>
            }
          </div>

          {/* Media section */}
          <div className={classNames("feature-media hidden lg:block w-full sm:9/12 max-h-full relative",
            {
              "lg:-right-32": getImagePlacement(index) === 'right',
              "lg:-left-32": getImagePlacement(index) === 'left',
              [getImagePlacement(index)]: true
            }
          )}>
            {/* Images */}
            {feature.media.mime.startsWith("image") && (
              <Image media={feature.media} className="w-full h-auto" />
            )}
            {/* Videos */}
            {feature.media.mime.startsWith("video") && (
              <Video
                media={feature.media}
                className="w-full h-auto"
                autoPlay
                controls={false}
              />
            )}
          </div>

        </div>
      ))}
    </div>
  );
};

export default BulletFeatureRowsGroup;
